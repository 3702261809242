/* eslint-disable */
/**
 * 设置列，导出表格
 */
import { saveAs } from 'file-saver'
import XLSX from "xlsx-style"


function datenum(v, date1904) {
  if (date1904) v += 1462;
  var epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data) {
  var ws = {};
  var range = {
    s: {
      c: 10000000,
      r: 10000000
    },
    e: {
      c: 0,
      r: 0
    }
  };
  // var _opts = []
  // if (opts) {
  //   _opts = opts
  // }
  // console.log('data==', data)
  for (var R = 0; R != data.length; ++R) {
    for (var C = 0; C != data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      var cell = {
        v: data[R][C],
      };


      if (cell.v == null) continue;
      var cell_ref = XLSX.utils.encode_cell({
        c: C,
        r: R,
      });

      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = XLSX.SSF._table[14];
        cell.v = datenum(cell.v);
      } else cell.t = 's';

      ws[cell_ref] = cell;
    }
  }
  if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
  return ws;
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

export function export_json_to_excel({
  multiHeader = [],
  header,
  data,
  filename,
  merges = [],
  autoWidth = true,
  bookType = 'xlsx'
} = {}) {
  /* original data */
  filename = filename || 'excel-list'
  data = [...data]
  if (header) {
    data.unshift(header);
  }

  for (var i = multiHeader.length - 1; i > -1; i--) {
    data.unshift(multiHeader[i])
  }

  var ws_name = "SheetJS";
  var wb = new Workbook(),
    ws = sheet_from_array_of_arrays(data);

  if (merges.length > 0) {
    // console.log('merges--------', merges);
    if (!ws['!merges']) ws['!merges'] = [];
    merges.forEach(item => {
      ws['!merges'].push(XLSX.utils.decode_range(item))
    })
  }

  if (autoWidth) {
    /*设置worksheet每列的最大宽度*/
    const colWidth = data.map(row => row.map(val => {
      /*先判断是否为null/undefined*/
      if (val == null) {
        return {
          'wch': 10
        };
      }
      /*再判断是否为中文*/
      else if (val.toString().charCodeAt(0) > 255) {
        return {
          'wch': val.toString().length * 2
        };
      } else {
        return {
          'wch': val.toString().length + 10
        };
      }
    }))
    /*以第一行为初始值*/
    var result = colWidth[0];
    for (var i = 1; i < colWidth.length; i++) {
      for (var j = 0; j < colWidth[i].length; j++) {
        if (result[j]['wch'] < colWidth[i][j]['wch']) {
          result[j]['wch'] = colWidth[i][j]['wch'];
        }
      }
    }
    ws['!cols'] = result;


    /*设置worksheet每列的最大宽度*/
    const rowHeight = data.map(row => row.map(val => {
      return {
        'hpx': 35
      };
    }))
    // console.log('rowHeight----', rowHeight);
    /*以第一行为初始值*/
    var result1 = rowHeight[0];
    for (var i = 1; i < rowHeight.length; i++) {
      for (var j = 0; j < rowHeight[i].length; j++) {
        if (result1[j]['hpx'] < rowHeight[i][j]['hpx']) {
          result1[j]['hpx'] = rowHeight[i][j]['hpx'];
        }
      }
    }
    ws['!rows'] = result1;
  }

  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;

  var dataInfo = wb.Sheets[wb.SheetNames[0]];
  // 设置单元格框线
  const borderAll = {
    top: {
      style: "thin"
    },
    bottom: {
      style: "thin"
    },
    left: {
      style: "thin"
    },
    right: {
      style: "thin"
    }
  };

  // 给所有单元格加上边框，内容居中，字体，字号，标题表头特殊格式部分后面替换
  for (var i in dataInfo) {
    if (i == "!ref" || i == "!cols" || i == "!rows" || i == "!merges") {
    } else if (i.indexOf("L") != -1 || i.indexOf("M") != -1 || i.indexOf("N") != -1 || i.indexOf("O") != -1) {  // 合并的单元格
      // console.log(i);
      dataInfo[i + ""].s = {
        font: {
          color: {
            rgb: "000000"
          },
        },
        border: borderAll,
        alignment: {
          horizontal: "center",
          vertical: "top"
        },
        fill: {
          fgColor: {
            rgb: "a6dfef"
          }
        },
      };
      // console.log(dataInfo[i + ""]);
    } else {
      dataInfo[i + ""].s = {
        // fill: {
        //   fgColor: 'red'
        // },
        border: borderAll,
        alignment: {
          horizontal: "center",
          vertical: "center"
        },
        // font: {
        //   name: "微软雅黑",
        //   sz: 12
        // }
      };
    }

  }

  // 设置表格样式
  const tableStyle = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"]

  // // 给标题、表格描述信息、表头等部分加上特殊格式
  tableStyle.some(function (v) {
    for (var j = 1; j < multiHeader.length + 1; j++) {
      const _v = v + j
      if (dataInfo[_v]) {
        dataInfo[_v].s = {};
        dataInfo[v + j].s = {
          font: {
            color: {
              rgb: "000000"
            },
            bold: true,
            italic: false,
            underline: false
          },
          border: borderAll,
          alignment: {
            horizontal: "center",
            vertical: "center"
          },
          fill: {
            fgColor: {
              rgb: "58bedb"
            }
          },
        };
      }
    }
  })

  var wbout = XLSX.write(wb, {
    bookType: bookType,
    bookSST: false,
    type: 'binary'
  });
  saveAs(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), `${filename}.${bookType}`);
}